import { Button, Form, Input, InputNumber, Select, Typography } from 'antd';
import { useCalculate } from 'data/indicators';
import './index.scss';

export default () => {
    const [form] = Form.useForm()
    const calculate = useCalculate();
    return (
        <section className="profile">
            <Typography>
                <Typography.Title level={3}>健康档案</Typography.Title>
                <Form
                    form={form}
                    initialValues={{
                        gender: '男',
                        age: 50,
                        ogtt_fpg: 4.0,
                        ogtt_120min: 5.0,
                        rpg: 4.0,
                        hba1c: 4.0,
                        lab_fins: 40,
                        cpeptide_0min: 2.0,
                        cpeptide_120min: 2.0,
                        tc: 4.0,
                        tg: 1.0,
                        ldlc: 2.0,
                        alt: 80,
                        ast: 80,
                        crea: 40,
                        egfr_original: 80,
                        blood_ua: 400,
                        systolic: 110,
                        diastolic: 70,
                        height: 180,
                        weight: 70,
                        bmi: 0,
                        waist: 86,
                        waist_hip_ratio: 0.6,
                        body_fat_ratio: 19,
                        visceral_fat_level: 8.0,
                        comp_disease: [],
                        cur_dm_drug: [],
                        gestational: '否',
                        dm_diag_original: '无',
                        icas_iaa: '阴性',
                        icas_ica: '阴性',
                        icas_gad: '阴性',
                        cardiopulmonary_grade: '中等',
                        ipaq_grade: '中',
                        hr_resting: 60,
                        body_fat_weight: 9.0,
                        diet_gi_recommend: '无',
                        diet_taboos: ['无禁忌'],
                        total_service_weeks: 8,
                    }}
                    onFinish={(profile) => {
                        if (profile.weight > 0 && profile.height > 0) {
                            profile.bmi = profile.weight * 10000 / profile.height / profile.height;
                        }
                        console.log(profile);
                        calculate(profile);
                    }}
                    layout="vertical"
                >
                    <Form.Item label="性别" name="gender" rules={[{required: true}]}>
                        <Select
                            options={[{
                                value: '男',
                                label: '男',
                            }, {
                                value: '女',
                                label: '女',
                            }]}
                        />
                    </Form.Item>
                    <Form.Item label="年龄" name="age" rules={[{required: true}]}>
                        <InputNumber min={1} max={150}/>
                    </Form.Item>
                    <Form.Item label="葡萄糖(Glu)-静脉血(空腹)" name="ogtt_fpg" rules={[{required: true}]}>
                        <InputNumber min={0} max={20} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="葡萄糖(Glu)-静脉血(2小时)" name="ogtt_120min"
                               rules={[{required: true}]}>
                        <InputNumber min={0} max={20} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="葡萄糖(Glu)-静脉血(随机)" name="rpg" rules={[{required: true}]}>
                        <InputNumber min={0} max={20} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="糖化血红蛋白A1c(HbA1c)-静脉血" name="hba1c" rules={[{required: true}]}>
                        <InputNumber min={0} max={100} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="胰岛素(Ins)-静脉血(空腹)" name="lab_fins" rules={[{required: true}]}>
                        <InputNumber min={0} max={300} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="C肽(C-P)-静脉血(空腹)" name="cpeptide_0min" rules={[{required: true}]}>
                        <InputNumber min={0} max={10} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="C肽(C-P)-静脉血(2小时)" name="cpeptide_120min"
                               rules={[{required: true}]}>
                        <InputNumber min={0} max={10} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="总胆固醇(TC)-静脉血" name="tc" rules={[{required: true}]}>
                        <InputNumber min={0} max={10} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="甘油三酯(TG)-静脉血" name="tg" rules={[{required: true}]}>
                        <InputNumber min={0} max={20} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="低密度脂蛋白胆固醇(LDL-C)-静脉血" name="ldlc"
                               rules={[{required: true}]}>
                        <InputNumber min={0} max={10} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="丙氨酸氨基转移酶(ALT)-静脉血" name="alt" rules={[{required: true}]}>
                        <InputNumber min={0} max={200}/>
                    </Form.Item>
                    <Form.Item label="天门冬氨酸氨基转移酶(AST)-静脉血" name="ast" rules={[{required: true}]}>
                        <InputNumber min={0} max={200}/>
                    </Form.Item>
                    <Form.Item label="肌酐(Crea)-静脉血" name="crea" rules={[{required: true}]}>
                        <InputNumber min={0} max={200}/>
                    </Form.Item>
                    <Form.Item label="估算的肾小球滤过率(eGFR)" name="egfr_original"
                               rules={[{required: true}]}>
                        <InputNumber min={0} max={200}/>
                    </Form.Item>
                    <Form.Item label="尿酸(UA)-静脉血" name="blood_ua" rules={[{required: true}]}>
                        <InputNumber min={0} max={1000}/>
                    </Form.Item>
                    <Form.Item label="收缩压(高值)" name="systolic" rules={[{required: true}]}>
                        <InputNumber min={0} max={200}/>
                    </Form.Item>
                    <Form.Item label="舒张压(低值)" name="diastolic" rules={[{required: true}]}>
                        <InputNumber min={0} max={200}/>
                    </Form.Item>
                    <Form.Item label="身高" name="height" rules={[{required: true}]}>
                        <InputNumber min={0} max={200}/>
                    </Form.Item>
                    <Form.Item label="体重" name="weight" rules={[{required: true}]}>
                        <InputNumber min={0} max={200}/>
                    </Form.Item>
                    <Form.Item hidden label="BMI" name="bmi" rules={[{required: false}]}>
                        <InputNumber min={0} max={200}/>
                    </Form.Item>
                    <Form.Item label="腰围" name="waist" rules={[{required: true}]}>
                        <InputNumber min={0} max={100}/>
                    </Form.Item>
                    <Form.Item label="腰臀比" name="waist_hip_ratio" rules={[{required: true}]}>
                        <InputNumber min={0} max={3.0} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="体脂比" name="body_fat_ratio" rules={[{required: true}]}>
                        <InputNumber min={0} max={100} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="内脏脂肪等级，来自体格检查" name="visceral_fat_level"
                               rules={[{required: true}]}>
                        <InputNumber min={0} max={100} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="合并疾病" name="comp_disease">
                        <Select
                            mode="multiple"
                            allowClear
                            options={[{
                                label: "高血脂",
                                value: "高血脂",
                            }, {
                                label: "脂肪肝",
                                value: "脂肪肝",
                            }, {
                                label: "颈动脉斑块",
                                value: "颈动脉斑块",
                            }, {
                                label: "高尿酸血症",
                                value: "高尿酸血症",
                            }, {
                                label: "痛风",
                                value: "痛风",
                            }, {
                                label: "痛风石",
                                value: "痛风石",
                            }, {
                                label: "关节积液抽吸",
                                value: "关节积液抽吸",
                            }, {
                                label: "左心室肥厚",
                                value: "左心室肥厚",
                            }, {
                                label: "高血压",
                                value: "高血压",
                            }, {
                                label: "深静脉血栓",
                                value: "深静脉血栓",
                            }, {
                                label: "周围神经病变",
                                value: "周围神经病变",
                            }, {
                                label: "视网膜病变",
                                value: "视网膜病变",
                            }, {
                                label: "糖尿病肾病3期及以上",
                                value: "糖尿病肾病3期及以上",
                            }, {
                                label: "糖尿病足",
                                value: "糖尿病足",
                            }, {
                                label: "心血管病变",
                                value: "心血管病变",
                            }, {
                                label: "房颤/严重心律失常",
                                value: "房颤/严重心律失常",
                            }, {
                                label: "脑血管病变",
                                value: "脑血管病变",
                            }, {
                                label: "严重肢体活动障碍",
                                value: "严重肢体活动障碍",
                            }, {
                                label: "肝硬化",
                                value: "肝硬化",
                            }, {
                                label: "恶性肿瘤",
                                value: "恶性肿瘤",
                            }, {
                                label: "其他",
                                value: "其他",
                            }]}
                        />
                    </Form.Item>
                    <Form.Item label="初始降糖药列表" name="cur_dm_drug">
                        <Select
                            mode="multiple"
                            allowClear
                            options={[{
                                label: '双胍类',
                                options: [{
                                    label: '二甲双胍',
                                    value: '二甲双胍',
                                }],
                            }, {
                                label: '二肽基肽酶-4',
                                options: [{
                                    label: '利格列汀',
                                    value: '利格列汀',
                                }, {
                                    label: '阿格列汀',
                                    value: '阿格列汀',
                                }, {
                                    label: '沙格列汀',
                                    value: '沙格列汀',
                                }, {
                                    label: '维格列汀',
                                    value: '维格列汀',
                                }, {
                                    label: '西格列汀',
                                    value: '西格列汀',
                                }],
                            }, {
                                label: '噻唑烷二酮类',
                                options: [{
                                    label: '罗格列酮',
                                    value: '罗格列酮',
                                }, {
                                    label: '吡格列酮',
                                    value: '吡格列酮',
                                }],
                            }, {
                                label: 'α-葡萄糖苷酶抑制剂',
                                options: [{
                                    label: '阿卡波糖',
                                    value: '阿卡波糖',
                                }, {
                                    label: '米格列醇',
                                    value: '米格列醇',
                                }, {
                                    label: '伏格列波糖',
                                    value: '伏格列波糖',
                                }],
                            }, {
                                label: '磺酰脲类衍生物',
                                options: [{
                                    label: '格列毗嗉',
                                    value: '格列毗嗉',
                                }, {
                                    label: '格列喹酮',
                                    value: '格列喹酮',
                                }, {
                                    label: '格列美脉',
                                    value: '格列美脉',
                                }, {
                                    label: '格列齐特',
                                    value: '格列齐特',
                                }],
                            }, {
                                label: '其他降糖药',
                                options: [{
                                    label: '那格列奈',
                                    value: '那格列奈',
                                }, {
                                    label: '瑞格列奈',
                                    value: '瑞格列奈',
                                }, {
                                    label: '米格列奈钙',
                                    value: '米格列奈钙',
                                }],
                            }, {
                                label: '胰高血糖素样肽-1(CLP-1)类似物',
                                options: [{
                                    label: '索马鲁肽',
                                    value: '索马鲁肽',
                                }, {
                                    label: '利拉鲁肽',
                                    value: '利拉鲁肽',
                                }, {
                                    label: '度拉糖肽',
                                    value: '度拉糖肽',
                                }, {
                                    label: '贝那鲁肽',
                                    value: '贝那鲁肽',
                                }, {
                                    label: '艾塞那肽',
                                    value: '艾塞那肽',
                                }, {
                                    label: '利司那肽',
                                    value: '利司那肽',
                                }],
                            }, {
                                label: '钠葡萄糖协同转运蛋白2(SGLT-2)抑制剂',
                                options: [{
                                    label: '达格列净',
                                    value: '达格列净',
                                }, {
                                    label: '恩格列浄',
                                    value: '恩格列浄',
                                }, {
                                    label: '卡格列浄',
                                    value: '卡格列浄',
                                }],
                            }, {
                                label: '胰岛素及类似物',
                                options: [{
                                    label: '门冬胰岛素',
                                    value: '门冬胰岛素',
                                }, {
                                    label: '赖脯胰岛素',
                                    value: '赖脯胰岛素',
                                }, {
                                    label: '谷赖胰岛素',
                                    value: '谷赖胰岛素',
                                }, {
                                    label: '生物合成人胰岛素',
                                    value: '生物合成人胰岛素',
                                }, {
                                    label: '重组人胰岛素r',
                                    value: '重组人胰岛素r',
                                }, {
                                    label: '精蛋白锌重组人胰岛素70/30',
                                    value: '精蛋白锌重组人胰岛素70/30',
                                }, {
                                    label: '精蛋白重组人胰岛素(30/70)',
                                    value: '精蛋白重组人胰岛素(30/70)',
                                }, {
                                    label: '精蛋白生物合成人胰島素30r',
                                    value: '精蛋白生物合成人胰島素30r',
                                }, {
                                    label: '精蛋白生物合成人胰岛素50r',
                                    value: '精蛋白生物合成人胰岛素50r',
                                }, {
                                    label: '门冬胰岛素30',
                                    value: '门冬胰岛素30',
                                }, {
                                    label: '门冬胰岛素50',
                                    value: '门冬胰岛素50',
                                }, {
                                    label: '精蛋白锌重组赖脯胰岛素25r',
                                    value: '精蛋白锌重组赖脯胰岛素25r',
                                }, {
                                    label: '精蛋白锌重组赖脯胰岛素50r',
                                    value: '精蛋白锌重组赖脯胰岛素50r',
                                }, {
                                    label: '德谷门冬双胰島素',
                                    value: '德谷门冬双胰島素',
                                }, {
                                    label: '精蛋白锌重组人胰岛素n',
                                    value: '精蛋白锌重组人胰岛素n',
                                }, {
                                    label: '精蛋白重组人胰岛素n',
                                    value: '精蛋白重组人胰岛素n',
                                }, {
                                    label: '甘精胰岛素',
                                    value: '甘精胰岛素',
                                }, {
                                    label: '重组甘精胰岛素',
                                    value: '重组甘精胰岛素',
                                }, {
                                    label: '地特胰岛素',
                                    value: '地特胰岛素',
                                }, {
                                    label: '德谷胰岛素',
                                    value: '德谷胰岛素',
                                }],
                            }, {
                                label: '口服复方降糖药',
                                options: [{
                                    label: '西格列汀二甲双胍',
                                    value: '西格列汀二甲双胍',
                                }, {
                                    label: '二甲双胍维格列汀',
                                    value: '二甲双胍维格列汀',
                                }, {
                                    label: '二甲双胍罗格列酮',
                                    value: '二甲双胍罗格列酮',
                                }, {
                                    label: '吡格列酮二甲双胍',
                                    value: '吡格列酮二甲双胍',
                                }, {
                                    label: '二甲双胍格列本脲',
                                    value: '二甲双胍格列本脲',
                                }, {
                                    label: '二甲双胍格列吡嗪',
                                    value: '二甲双胍格列吡嗪',
                                }],
                            }]}
                        />
                    </Form.Item>
                    <Form.Item label="是否妊娠期" name="gestational" rules={[{required: true}]}>
                        <Select
                            options={[{
                                label: '是',
                                value: '是',
                            }, {
                                label: '否',
                                value: '否',
                            }]}
                        />
                    </Form.Item>
                    <Form.Item label="原始糖尿病诊断" name="dm_diag_original" rules={[{required: true}]}>
                        <Select
                            options={[{
                                label: '无',
                                value: '',
                            }, {
                                label: '空腹血糖受损',
                                value: '空腹血糖受损',
                            }, {
                                label: '糖耐量减低',
                                value: '糖耐量减低',
                            }, {
                                label: '2型糖尿病',
                                value: '2型糖尿病',
                            }, {
                                label: '1型糖尿病',
                                value: '1型糖尿病',
                            }, {
                                label: '其他类型糖尿病',
                                value: '其他类型糖尿病',
                            }]}
                        />
                    </Form.Item>
                    <Form.Item label="抗胰岛素抗体(IAA)" name="icas_iaa" rules={[{required: true}]}>
                        <Select
                            options={[{
                                label: '阳性',
                                value: '阳性',
                            }, {
                                label: '阴性',
                                value: '阴性',
                            }]}
                        />
                    </Form.Item>
                    <Form.Item label="抗胰岛细胞抗体(ICA)" name="icas_ica" rules={[{required: true}]}>
                        <Select
                            options={[{
                                label: '阳性',
                                value: '阳性',
                            }, {
                                label: '阴性',
                                value: '阴性',
                            }]}
                        />
                    </Form.Item>
                    <Form.Item label="抗谷氨酸脱羧酶抗体(GAD)" name="icas_gad" rules={[{required: true}]}>
                        <Select
                            options={[{
                                label: '阳性',
                                value: '阳性',
                            }, {
                                label: '阴性',
                                value: '阴性',
                            }]}
                        />
                    </Form.Item>
                    {/*<Form.Item label="有氧运动强度(医生选择)" name="ques_aerobic_intensity" rules={[{required: true}]}>*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label="心肺功能分级" name="cardiopulmonary_grade" rules={[{required: true}]}>
                        <Select
                            options={[{
                                label: '极低',
                                value: '极低',
                            }, {
                                label: '低',
                                value: '低',
                            }, {
                                label: '中低',
                                value: '中低',
                            }, {
                                label: '中等',
                                value: '中等',
                            }, {
                                label: '中高',
                                value: '中高',
                            }, {
                                label: '高',
                                value: '高',
                            }]}
                        />
                    </Form.Item>
                    <Form.Item label="体力活动分级" name="ipaq_grade" rules={[{required: true}]}>
                        <Select
                            options={[{
                                label: '低',
                                value: '低',
                            }, {
                                label: '中',
                                value: '中',
                            }, {
                                label: '高',
                                value: '高',
                            }]}
                        />
                    </Form.Item>
                    <Form.Item label="静息心率" name="hr_resting" rules={[{required: true}]}>
                        <InputNumber min={40} max={160}/>
                    </Form.Item>
                    <Form.Item hidden label="运动方案名称" name="training_schema_lable">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="体脂重量" name="body_fat_weight">
                        <InputNumber min={0} max={100} step={0.1}/>
                    </Form.Item>
                    <Form.Item label="推荐GI等级" name="diet_gi_recommend">
                        <Select
                            options={[{
                                label: "无",
                                value: "",
                            }, {
                                label: "低",
                                value: "低",
                            }, {
                                label: "中等",
                                value: "中等",
                            }, {
                                label: "中高",
                                value: "中高",
                            }, {
                                label: "高",
                                value: "高",
                            }]}
                        />
                    </Form.Item>
                    <Form.Item label="饮食禁忌" name="diet_taboos" rules={[{required: true}]}>
                        <Select
                            mode="multiple"
                            allowClear
                            options={[{
                                label: '无禁忌',
                                value: '无禁忌',
                            }, {
                                label: '蛋类',
                                options: [{
                                    label: '鸡蛋',
                                    value: '鸡蛋',
                                }, {
                                    label: '鹌鹑蛋',
                                    value: '鹌鹑蛋',
                                }, {
                                    label: '鸭蛋',
                                    value: '鸭蛋',
                                }],
                            }, {
                                label: '奶制品',
                                options: [{
                                    label: '牛奶',
                                    value: '牛奶',
                                }, {
                                    label: '豆浆',
                                    value: '豆浆',
                                }],
                            }, {
                                label: '海鲜',
                                options: [{
                                    label: '鱼',
                                    value: '鱼',
                                }, {
                                    label: '虾',
                                    value: '虾',
                                }, {
                                    label: '贝类',
                                    value: '贝类',
                                }, {
                                    label: '螃蟹',
                                    value: '螃蟹',
                                }],
                            }, {
                                label: '肉类',
                                options: [{
                                    label: '猪肉',
                                    value: '猪肉',
                                }, {
                                    label: '牛肉',
                                    value: '牛肉',
                                }, {
                                    label: '羊肉',
                                    value: '羊肉',
                                }, {
                                    label: '鸡肉',
                                    value: '鸡肉',
                                }, {
                                    label: '鸭肉',
                                    value: '鸭肉',
                                }],
                            }, {
                                label: '豆制品',
                                options: [{
                                    label: '黄豆',
                                    value: '黄豆',
                                }, {
                                    label: '腐竹',
                                    value: '腐竹',
                                }, {
                                    label: '豆腐干',
                                    value: '豆腐干',
                                }, {
                                    label: '豆腐丝',
                                    value: '豆腐丝',
                                }, {
                                    label: '豆腐',
                                    value: '豆腐',
                                }, {
                                    label: '毛豆',
                                    value: '毛豆',
                                }],
                            }, {
                                label: '坚果类',
                                options: [{
                                    label: '花生仁',
                                    value: '花生仁',
                                }, {
                                    label: '核桃仁',
                                    value: '核桃仁',
                                }, {
                                    label: '腰果',
                                    value: '腰果',
                                }, {
                                    label: '大杏仁',
                                    value: '大杏仁',
                                }],
                            }, {
                                label: '其他',
                                options: [{
                                    label: '芥蓝菜',
                                    value: '芥蓝菜',
                                }, {
                                    label: '瓢菜',
                                    value: '瓢菜',
                                }, {
                                    label: '蕹菜',
                                    value: '蕹菜',
                                }, {
                                    label: '苋菜',
                                    value: '苋菜',
                                }, {
                                    label: '龙须菜',
                                    value: '龙须菜',
                                }, {
                                    label: '韭菜',
                                    value: '韭菜',
                                }, {
                                    label: '茴香',
                                    value: '茴香',
                                }, {
                                    label: '茼蒿',
                                    value: '茼蒿',
                                }, {
                                    label: '空心菜',
                                    value: '空心菜',
                                }],
                            }]}
                        />
                    </Form.Item>
                    {/*<Form.Item hidden label="混合类主食名称" name="dumpling_name">*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label="总服务周数" name="total_service_weeks" rules={[{required: true}]}>
                        <InputNumber min={1} max={50}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            开始计算
                        </Button>
                    </Form.Item>
                </Form>
            </Typography>
        </section>
    )
};
