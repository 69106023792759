import './index.scss';

export default () => (
    <div className="brand">
        <img
            alt="长帆健康"
            src="/images/favicon.png"
            width="32"
            height="32"
        />
        <span>
            <span className="blue">Sail</span>
            <span className="green">Health</span>
        </span>
    </div>
);
