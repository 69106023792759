import { Descriptions, Empty, Table, Typography } from 'antd';
import state, { columns } from 'data/state';
import { useRecoilValue } from 'recoil';

export default () => {
    const patient = useRecoilValue(state.patient);
    return patient === null ? <Empty description="请选择一位患者查看信息"/> : (
        <Typography>
            <Typography.Title>基本信息</Typography.Title>
            <Descriptions bordered>
                <Descriptions.Item label="患者编号">{patient[columns['患者编号']]}</Descriptions.Item>
                <Descriptions.Item label="开始日期">{patient[columns['开始日期']]}</Descriptions.Item>
                <Descriptions.Item label="结束日期">{patient[columns['结束日期']]}</Descriptions.Item>
                <Descriptions.Item label="期数">{patient[columns['期数']]}</Descriptions.Item>
                <Descriptions.Item label="分期">{patient[columns['分期']]}</Descriptions.Item>
                <Descriptions.Item label="管理期">{patient[columns['管理期']]}</Descriptions.Item>
                <Descriptions.Item label="性别">{patient[columns['性别1男2女']]}</Descriptions.Item>
                <Descriptions.Item label="年龄">{patient[columns['年龄']]}</Descriptions.Item>
                <Descriptions.Item label="身高">{patient[columns['身高']]}</Descriptions.Item>
            </Descriptions>
            <Typography.Title>病情信息</Typography.Title>
            <Descriptions bordered>
                <Descriptions.Item label="体脂量">{patient[columns['体脂量']]}</Descriptions.Item>
                <Descriptions.Item label="患病年限">{patient[columns['患病年限']]}</Descriptions.Item>
                <Descriptions.Item label="降糖药服用情况">{patient[columns['降糖药服用情况']]}</Descriptions.Item>
                <Descriptions.Item label="胰岛素注射情况">{patient[columns['胰岛素注射情况']]}</Descriptions.Item>
                <Descriptions.Item label="胰岛素种类">{patient[columns['胰岛素种类']]}</Descriptions.Item>
                <Descriptions.Item label="糖尿病并发症">{patient[columns['糖尿病并发症']]}</Descriptions.Item>
                <Descriptions.Item label="并发症种类">{patient[columns['并发症种类']]}</Descriptions.Item>
                <Descriptions.Item label="其他种类并发症">{patient[columns['其他种类并发症']]}</Descriptions.Item>
                <Descriptions.Item label="家族史及家族聚集性">{patient[columns['家族史及家族聚集性']]}</Descriptions.Item>
                <Descriptions.Item label="家族患病者">{patient[columns['家族患病者']]}</Descriptions.Item>
                <Descriptions.Item label="既往病史">{patient[columns['既往病史']]}</Descriptions.Item>
                <Descriptions.Item label="既往病史种类">{patient[columns['既往病史种类']]}</Descriptions.Item>
                <Descriptions.Item label="其他疾病史">{patient[columns['其他疾病史']]}</Descriptions.Item>
                <Descriptions.Item label="手术史">{patient[columns['手术史']]}</Descriptions.Item>
                <Descriptions.Item label="手术种类">{patient[columns['手术种类']]}</Descriptions.Item>
                <Descriptions.Item label="过敏史">{patient[columns['过敏史']]}</Descriptions.Item>
                <Descriptions.Item label="过敏药物">{patient[columns['过敏药物']]}</Descriptions.Item>
                <Descriptions.Item label="过敏食物">{patient[columns['过敏食物']]}</Descriptions.Item>
            </Descriptions>
            <Typography.Title>档案信息</Typography.Title>
            <Table
                pagination={false}
                columns={[{
                    key: 'title',
                    dataIndex: 0,
                    title: '',
                    width: '40%',
                }, {
                    key: 'before',
                    dataIndex: 1,
                    title: '入组前',
                    width: '30%',
                }, {
                    key: 'after',
                    dataIndex: 2,
                    title: '管理后',
                    width: '30%',
                }]}
                dataSource={[
                    ['糖化血红蛋白', patient[columns['糖化血红蛋白']], patient[columns['逆转后糖化血红蛋白']]],
                    ['收缩压', patient[columns['收缩压']], patient[columns['逆转后收缩压']]],
                    ['舒张压', patient[columns['舒张压']], patient[columns['逆转后舒张压']]],
                    ['心率', patient[columns['心率']], patient[columns['逆转后心率']]],
                    ['体重', patient[columns['体重']], patient[columns['逆转后体重']]],
                    ['BMI', patient[columns['BMI']], patient[columns['逆转后BMI']]],
                    ['内脂等级', patient[columns['内脂等级']], patient[columns['逆转后内脂']]],
                    ['基础代谢', patient[columns['基础代谢']], patient[columns['逆转后基础代谢']]],
                    ['体脂率', patient[columns['体脂率']], patient[columns['逆转后脂肪率']]],
                    ['肌肉量', patient[columns['肌肉量']], patient[columns['逆转后肌肉量']]],
                    ['腰围', patient[columns['腰围']], patient[columns['逆转后腰围']]],
                    ['臀围', patient[columns['臀围']], patient[columns['逆转后臀围']]],
                    ['腰臀比', patient[columns['腰臀比']], patient[columns['逆转后腰臀比']]],
                    ['用药情况', patient[columns['用药情况']], patient[columns['逆转后用药情况']]],
                    ['空腹血糖', patient[columns['空腹血糖']], patient[columns['逆转后血糖0H']]],
                    ['血糖OGTT-30分', patient[columns['血糖OGTT-30分']], patient[columns['逆转后血糖0.5H']]],
                    ['血糖OGTT-60分', patient[columns['血糖OGTT-60分']], patient[columns['逆转后血糖1H']]],
                    ['血糖OGTT-120分', patient[columns['血糖OGTT-120分']], patient[columns['逆转后血糖2H']]],
                    ['血糖OGTT-180分', patient[columns['血糖OGTT-180分']], patient[columns['逆转后血糖3H']]],
                    ['空腹胰岛素IU/ml', patient[columns['空腹胰岛素IU/ml']], patient[columns['逆转后胰岛素释放0h']]],
                    ['胰岛素OGTT-30分IU/ml', patient[columns['胰岛素OGTT-30分IU/ml']], patient[columns['逆转后胰岛素释放0.5h']]],
                    ['胰岛素OGTT-60分IU/ml', patient[columns['胰岛素OGTT-60分IU/ml']], patient[columns['逆转后胰岛素释放1h']]],
                    ['胰岛素OGTT-120分IU/ml', patient[columns['胰岛素OGTT-120分IU/ml']], patient[columns['逆转后胰岛素释放2h']]],
                    ['胰岛素OGTT-180分IU/ml', patient[columns['胰岛素OGTT-180分IU/ml']], patient[columns['逆转后胰岛素释放3h']]],

                    ['空腹胰岛素pmol/L', patient[columns['空腹胰岛素pmol/L']], patient[columns['逆转后胰岛素释放0h（pmol/L)']]],
                    ['胰岛素OGTT-30分pmol/L', patient[columns['胰岛素OGTT-30分pmol/L']], patient[columns['逆转后胰岛素释放0.5h（pmol/L)']]],
                    ['胰岛素OGTT-60分pmol/L', patient[columns['胰岛素OGTT-60分pmol/L']], patient[columns['逆转后胰岛素释放1h（pmol/L)']]],
                    ['胰岛素OGTT-120分pmol/L', patient[columns['胰岛素OGTT-120分pmol/L']], patient[columns['逆转后胰岛素释放2h（pmol/L)']]],
                    ['胰岛素OGTT-180分pmol/L', patient[columns['胰岛素OGTT-180分pmol/L']], patient[columns['逆转后胰岛素释放3h（pmol/L)']]],

                    ['空腹C肽nmol/L', patient[columns['空腹C肽nmol/L']], patient[columns['逆转后C-P释放0H']]],
                    ['C肽OGTT-30分钟nmol/L', patient[columns['C肽OGTT-30分钟nmol/L']], patient[columns['逆转后C-P释放0.5H']]],
                    ['C肽OGTT-60分钟nmol/L', patient[columns['C肽OGTT-60分钟nmol/L']], patient[columns['逆转后C-P释放1H']]],
                    ['C肽OGTT-120分钟nmol/L', patient[columns['C肽OGTT-120分钟nmol/L']], patient[columns['逆转后C-P释放2H']]],
                    ['C肽OGTT-180分钟nmol/L', patient[columns['C肽OGTT-180分钟nmol/L']], patient[columns['逆转后C-P释放3H']]],

                    ['空腹C肽ng/ml', patient[columns['空腹C肽ng/ml']], patient[columns['逆转后C-P释放0H(nmol/L)']]],
                    ['C肽OGTT-30分钟ng/ml', patient[columns['C肽OGTT-30分钟ng/ml']], patient[columns['逆转后C-P释放0.5H(nmol/L)']]],
                    ['C肽OGTT-60分钟ng/ml', patient[columns['C肽OGTT-60分钟ng/ml']], patient[columns['逆转后C-P释放1H(nmol/L)']]],
                    ['C肽OGTT-120分钟ng/ml', patient[columns['C肽OGTT-120分钟ng/ml']], patient[columns['逆转后C-P释放2H(nmol/L)']]],
                    ['C肽OGTT-180分钟ng/ml', patient[columns['C肽OGTT-180分钟ng/ml']], patient[columns['逆转后C-P释放3H(nmol/L)']]],

                    ['丙氨酸转移酶', patient[columns['丙氨酸转移酶']], patient[columns['逆转后丙氨酸转移酶']]],
                    ['天门冬氨酸转移酶', patient[columns['天门冬氨酸转移酶']], patient[columns['逆转后天门冬氨酸转移酶']]],
                    ['直接胆红素', patient[columns['直接胆红素']], patient[columns['逆转后直接胆红素']]],
                    ['间接胆红素', patient[columns['间接胆红素']], patient[columns['逆转后间接胆红素']]],
                    ['总胆红素', patient[columns['总胆红素']], patient[columns['逆转后总胆红素']]],
                    ['血浆尿素氮', patient[columns['血浆尿素氮']], patient[columns['逆转后血浆尿素氮']]],
                    ['血浆肌酐', patient[columns['血浆肌酐']], patient[columns['逆转后血浆肌酐']]],
                    ['尿酸', patient[columns['尿酸']], patient[columns['逆转后尿酸']]],
                    ['尿蛋白（定量）', patient[columns['尿蛋白（定量）']], patient[columns['逆转后尿蛋白（定量）']]],
                    ['尿蛋白（定性）', patient[columns['尿蛋白（定性）']], patient[columns['逆转后尿蛋白（定性）']]],
                    ['尿葡萄糖', patient[columns['尿葡萄糖']], patient[columns['逆转后尿葡萄糖']]],
                    ['尿微量白蛋白', patient[columns['尿微量白蛋白']], patient[columns['逆转后尿微量白蛋白']]],
                    ['甘油三酯', patient[columns['甘油三酯']], patient[columns['逆转后甘油三酯']]],
                    ['总胆固醇', patient[columns['总胆固醇']], patient[columns['逆转后总胆固醇']]],
                    ['高密度脂蛋白', patient[columns['高密度脂蛋白']], patient[columns['逆转后高密度脂蛋白']]],
                    ['低密度脂蛋白', patient[columns['低密度脂蛋白']], patient[columns['逆转后低密度脂蛋白']]],
                    ['载脂蛋白A', patient[columns['载脂蛋白A']], patient[columns['逆转后载脂蛋白A']]],
                    ['载脂蛋白B', patient[columns['载脂蛋白B']], patient[columns['逆转后载脂蛋白B']]],
                    ['红细胞', patient[columns['红细胞']], patient[columns['逆转后红细胞']]],
                    ['白细胞计数', patient[columns['白细胞计数']], patient[columns['逆转后白细胞计数']]],
                    ['血小板', patient[columns['血小板']], patient[columns['逆转后血小板']]],
                    ['血红蛋白', patient[columns['血红蛋白']], patient[columns['逆转后血红蛋白']]],
                    ['胰岛素抗体', patient[columns['胰岛素抗体']], patient[columns['逆转后胰岛素抗体']]],
                    ['B超', patient[columns['B超']], patient[columns['逆转后B超']]],
                    ['心电图', patient[columns['心电图']], patient[columns['逆转后心电图']]],
                    ['心电图异常情况', patient[columns['心电图异常情况']], patient[columns['逆转后心电图异常情况']]],
                    ['焦虑', patient[columns['焦虑']], patient[columns['逆转后焦虑']]],
                    ['抑郁', patient[columns['抑郁']], patient[columns['逆转后抑郁']]],
                    ['知信行评分', patient[columns['知信行评分']], patient[columns['逆转后知信行评分']]],
                ]}/>
            <Typography.Title>档案总结</Typography.Title>
            <Descriptions bordered>
                <Descriptions.Item label="数据前后对比">{patient[columns['数据前后对比']]}</Descriptions.Item>
                <Descriptions.Item label="逆转后BMI降低">{patient[columns['逆转后BMI降低']]}</Descriptions.Item>
                <Descriptions.Item label="逆转后体重降低">{patient[columns['逆转后体重降低']]}</Descriptions.Item>
                <Descriptions.Item label="体重变化降幅">{patient[columns['体重变化降幅']]}</Descriptions.Item>
                <Descriptions.Item label="逆转后内脂降低">{patient[columns['逆转后内脂降低']]}</Descriptions.Item>
                <Descriptions.Item label="脂肪率降低">{patient[columns['脂肪率降低']]}</Descriptions.Item>
                <Descriptions.Item
                    label="逆转后肌肉量（前-后）">{patient[columns['逆转后肌肉量（前-后）']]}</Descriptions.Item>
                <Descriptions.Item
                    label="逆转后基础代谢（前-后）">{patient[columns['逆转后基础代谢（前-后）']]}</Descriptions.Item>
                <Descriptions.Item
                    label="逆转后糖化血红蛋白（后-前）">{patient[columns['逆转后糖化血红蛋白（后-前）']]}</Descriptions.Item>
                <Descriptions.Item
                    label="BMI需控制在18.5~23.9之间">{patient[columns['BMI需控制在18.5~23.9之间']]}</Descriptions.Item>
                <Descriptions.Item
                    label="或BMI在24以上的体重减少10%">{patient[columns['或BMI在24以上的体重减少10%']]}</Descriptions.Item>
                <Descriptions.Item label="综合体重达标">{patient[columns['综合体重达标']]}</Descriptions.Item>
            </Descriptions>
        </Typography>
    );
};
