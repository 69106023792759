import Patient from 'views/components/Patient';
import Patients from 'views/components/Patients';
import Columns, { Content, Left } from 'views/layouts/Columns';
import './index.scss';

export default () => (
    <Columns bordered>
        <Left>
            <Patients/>
        </Left>
        <Content>
            <Patient />
        </Content>
    </Columns>
);
