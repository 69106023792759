import state from 'data/state';
import { useRequest } from 'middlewares/http';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

export const useFetchPatients = () => {
    const setPatients = useSetRecoilState(state.patients);
    const { run } = useRequest('/api/patients');
    return useCallback(() => {
        run().then((patients) => {
            setPatients(patients);
        });
    }, [])
};
