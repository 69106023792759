import { Descriptions } from 'antd';

export default ({ data: {
    is_applicable = '',
    not_applicable_reason = [],
    not_vam_reason = [],
} }) => {
    return (
        <Descriptions title="" bordered column={1}>
            <Descriptions.Item label="是否适用本疗法">{is_applicable}</Descriptions.Item>
            <Descriptions.Item label="不适用的原因">
                <ul>{not_applicable_reason.map((reason, index) => <li key={`${index}`}>{reason}</li>)}</ul>
            </Descriptions.Item>
            <Descriptions.Item label="高风险因素">
                <ul>{not_vam_reason.map((reason, index) => <li key={`${index}`}>{reason}</li>)}</ul>
            </Descriptions.Item>
        </Descriptions>
    )
}
