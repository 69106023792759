import { Descriptions } from 'antd';

export default ({ data: {
    daily_rmr = '',
    metabolic_coef = '',
    daily_calories_cost = '',
    intake_coef = '',
    daily_calories_intake = '',
    daily_calories_delta = '',
    daily_fat_loss = '',
    body_manage_type = '',
} }) => {
    return (
        <Descriptions title="" bordered column={1}>
            <Descriptions.Item label="静息代谢率(kcal)">{daily_rmr || ''}</Descriptions.Item>
            <Descriptions.Item label="代谢提升系数">{metabolic_coef || ''}</Descriptions.Item>
            <Descriptions.Item label="日消耗热量(kcal)">{daily_calories_cost || ''}</Descriptions.Item>
            <Descriptions.Item label="能量摄取系数(中间值不返回)">{intake_coef || ''}</Descriptions.Item>
            <Descriptions.Item label="日摄取热量(kcal)">{daily_calories_intake || ''}</Descriptions.Item>
            <Descriptions.Item label="日热量差(kcal)">{daily_calories_delta || ''}</Descriptions.Item>
            <Descriptions.Item label="日消耗脂肪(g)">{daily_fat_loss || ''}</Descriptions.Item>
            <Descriptions.Item label="体重管理类型">{body_manage_type || ''}</Descriptions.Item>
        </Descriptions>
    )
}
