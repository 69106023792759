import { Route, Routes } from 'react-router-dom';
import Viewport from 'views/layouts/Viewport';
import IndicatorCalculator from 'views/pages/IndicatorCalculator';
import Skeleton from 'views/parts/Skeleton';
import NotFound from './pages/NotFound';
import PatientManagement from './pages/PatientManagement';

export default () => {
    return (
        <Viewport>
            <Routes>
                <Route path="/" element={<Skeleton />}>
                    <Route index element={<IndicatorCalculator />} />
                    <Route path="patients" element={<PatientManagement />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Viewport>
    );
};
