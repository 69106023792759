import state from 'data/state';
import { useRequest } from 'middlewares/http';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

export const useCalculate = () => {
    const setIndicators = useSetRecoilState(state.indicators);
    const { run } = useRequest('/api/indicators', 'POST');
    return useCallback((profile) => {
        run(profile).then((indicators) => {
            setIndicators(indicators);
        });
    }, [])
};
