import { atom } from 'recoil';

export const columns = {
    '患者编号': 0,
    '期数': 1,
    '分期': 2,
    '管理期': 3,
    '开始日期': 4,
    '结束日期': 5,
    '性别1男2女': 6,
    '年龄': 7,
    '收缩压': 8,
    '舒张压': 9,
    '心率': 10,
    '身高': 11,
    '体重': 12,
    'BMI': 13,
    '内脂等级': 14,
    '基础代谢': 15,
    '体脂量': 16,
    '体脂率': 17,
    '肌肉量': 18,
    '腰围': 19,
    '臀围': 20,
    '腰臀比': 21,
    '患病年限': 22,
    '降糖药服用情况': 23,
    '胰岛素注射情况': 24,
    '胰岛素种类': 25,
    '糖尿病并发症': 26,
    '并发症种类': 27,
    '其他种类并发症': 28,
    '家族史及家族聚集性': 29,
    '家族患病者': 30,
    '既往病史': 31,
    '既往病史种类': 32,
    '其他疾病史': 33,
    '用药情况': 34,
    '手术史': 35,
    '手术种类': 36,
    '过敏史': 37,
    '过敏药物': 38,
    '过敏食物': 39,
    '空腹血糖': 40,
    '血糖OGTT-30分': 41,
    '血糖OGTT-60分': 42,
    '血糖OGTT-120分': 43,
    '血糖OGTT-180分': 44,
    '空腹胰岛素IU/ml': 45,
    '空腹胰岛素pmol/L': 46,
    '胰岛素OGTT-30分IU/ml': 47,
    '胰岛素OGTT-30分pmol/L': 48,
    '胰岛素OGTT-60分IU/ml': 49,
    '胰岛素OGTT-60分pmol/L': 50,
    '胰岛素OGTT-120分IU/ml': 51,
    '胰岛素OGTT-120分pmol/L': 52,
    '胰岛素OGTT-180分IU/ml': 53,
    '胰岛素OGTT-180分pmol/L': 54,
    '空腹C肽nmol/L': 55,
    '空腹C肽ng/ml': 56,
    'C肽OGTT-30分钟nmol/L': 57,
    'C肽OGTT-30分钟ng/ml': 58,
    'C肽OGTT-60分钟nmol/L': 59,
    'C肽OGTT-60分钟ng/ml': 60,
    'C肽OGTT-120分钟nmol/L': 61,
    'C肽OGTT-120分钟ng/ml': 62,
    'C肽OGTT-180分钟nmol/L': 63,
    'C肽OGTT-180分钟ng/ml': 64,
    '糖化血红蛋白': 65,
    '丙氨酸转移酶': 66,
    '天门冬氨酸转移酶': 67,
    '直接胆红素': 68,
    '间接胆红素': 69,
    '总胆红素': 70,
    '血浆尿素氮': 71,
    '血浆肌酐': 72,
    '尿酸': 73,
    '尿蛋白（定量）': 74,
    '尿蛋白（定性）': 75,
    '尿葡萄糖': 76,
    '尿微量白蛋白': 77,
    '甘油三酯': 78,
    '总胆固醇': 79,
    '高密度脂蛋白': 80,
    '低密度脂蛋白': 81,
    '载脂蛋白A': 82,
    '载脂蛋白B': 83,
    '红细胞': 84,
    '白细胞计数': 85,
    '血小板': 86,
    '血红蛋白': 87,
    '胰岛素抗体': 88,
    'B超': 89,
    '心电图': 90,
    '心电图异常情况': 91,
    '焦虑': 92,
    '抑郁': 93,
    '知信行评分': 94,
    '第一天18点': 95,
    '第一天22点': 96,
    '第二天8点': 97,
    '第二天10点': 98,
    '第二天12点': 99,
    '第二天14点': 100,
    '第二天18点': 101,
    '第二天20点': 102,
    '第二天22点': 103,
    '第三天8点': 104,
    '逆转后用药情况': 105,
    '逆转后BMI': 106,
    '逆转后体重': 107,
    '逆转后内脂': 108,
    '逆转后脂肪率': 109,
    '逆转后肌肉量': 110,
    '逆转后基础代谢': 111,
    '逆转后糖化血红蛋白': 112,
    '逆转后胰岛素释放0h': 113,
    '逆转后胰岛素释放0.5h': 114,
    '逆转后胰岛素释放1h': 115,
    '逆转后胰岛素释放2h': 116,
    '逆转后胰岛素释放3h': 117,
    '逆转后胰岛素释放0h（pmol/L)': 118,
    '逆转后胰岛素释放0.5h（pmol/L)': 119,
    '逆转后胰岛素释放1h（pmol/L)': 120,
    '逆转后胰岛素释放2h（pmol/L)': 121,
    '逆转后胰岛素释放3h（pmol/L)': 122,
    '逆转后血糖0H': 123,
    '逆转后血糖0.5H': 124,
    '逆转后血糖1H': 125,
    '逆转后血糖2H': 126,
    '逆转后血糖3H': 127,
    '逆转后C-P释放0H': 128,
    '逆转后C-P释放0.5H': 129,
    '逆转后C-P释放1H': 130,
    '逆转后C-P释放2H': 131,
    '逆转后C-P释放3H': 132,
    '逆转后C-P释放0H(nmol/L)': 133,
    '逆转后C-P释放0.5H(nmol/L)': 134,
    '逆转后C-P释放1H(nmol/L)': 135,
    '逆转后C-P释放2H(nmol/L)': 136,
    '逆转后C-P释放3H(nmol/L)': 137,
    '逆转后收缩压': 138,
    '逆转后舒张压': 139,
    '逆转后心率': 140,
    '逆转后腰围': 141,
    '逆转后臀围': 142,
    '逆转后腰臀比': 143,
    '逆转后丙氨酸转移酶': 144,
    '逆转后天门冬氨酸转移酶': 145,
    '逆转后直接胆红素': 146,
    '逆转后间接胆红素': 147,
    '逆转后总胆红素': 148,
    '逆转后血浆尿素氮': 149,
    '逆转后血浆肌酐': 150,
    '逆转后尿酸': 151,
    '逆转后尿蛋白（定量）': 152,
    '逆转后尿蛋白（定性）': 153,
    '逆转后尿葡萄糖': 154,
    '逆转后尿微量白蛋白': 155,
    '逆转后甘油三酯': 156,
    '逆转后总胆固醇': 157,
    '逆转后高密度脂蛋白': 158,
    '逆转后低密度脂蛋白': 159,
    '逆转后载脂蛋白A': 160,
    '逆转后载脂蛋白B': 161,
    '逆转后红细胞': 162,
    '逆转后白细胞计数': 163,
    '逆转后血小板': 164,
    '逆转后血红蛋白': 165,
    '逆转后胰岛素抗体': 166,
    '逆转后B超': 167,
    '逆转后心电图': 168,
    '逆转后心电图异常情况': 169,
    '逆转后焦虑': 170,
    '逆转后抑郁': 171,
    '逆转后知信行评分': 172,
    '逆转后C-P释放0h': 173,
    '逆转后C-P释放0.5h': 174,
    '逆转后C-P释放1h': 175,
    '逆转后C-P释放2h': 176,
    '逆转后C-P释放3h': 177,
    '逆转后胰岛素释放0h(pmol/L)': 178,
    '逆转后胰岛素释放0.5h(pmol/L)': 179,
    '逆转后胰岛素释放1h(pmol/L)': 180,
    '逆转后胰岛素释放2h(pmol/L)': 181,
    '逆转后胰岛素释放3h(pmol/L)': 182,
    '数据前后对比': 183,
    '逆转后BMI降低': 184,
    '逆转后体重降低': 185,
    '体重变化降幅': 186,
    '逆转后内脂降低': 187,
    '脂肪率降低': 188,
    '逆转后肌肉量（前-后）': 189,
    '逆转后基础代谢（前-后）': 190,
    '逆转后糖化血红蛋白（后-前）': 191,
    'BMI需控制在18.5~23.9之间': 192,
    '或BMI在24以上的体重减少10%': 193,
    '综合体重达标': 194,
};

const state = {
    /**
     * 完整的患者档案列表。
     */
    patients: atom({
        key: 'patients',
        default: [],
    }),

    /**
     * 选中的患者档案信息。
     */
    patient: atom({
        key: 'patient',
        default: null,
    }),

    /**
     * 出参。
     */
    indicators: atom({
        key: 'indicators',
        default: {},
    }),
};

export default state;
