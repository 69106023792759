import { App } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import Application from 'views';

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
    <RecoilRoot>
        <BrowserRouter>
            <App>
                <Application />
            </App>
        </BrowserRouter>
    </RecoilRoot>,
);
