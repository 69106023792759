import { Outlet } from 'react-router-dom';
import Navigator from 'views/components/Navigator';
import Rows, { Content as RowContent, Header } from 'views/layouts/Rows';

export default () => {
    return (
        <Rows>
            <Header>
                <Navigator/>
            </Header>
            <RowContent>
                <Outlet/>
            </RowContent>
        </Rows>
    );
};
