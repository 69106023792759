import { App } from 'antd';
import fetch from 'cross-fetch';
import { useCallback, useState } from 'react';

/**
 * 参与作为URL一部分的请求方式。
 */
const requestByUrl = (path, params) => {
    const query = new URLSearchParams(params).toString();
    const url = query.length ? `${path}?${query}` : path;
    return fetch(url, {method: 'GET'});
};

/**
 * 参与作为BODY的请求方式。
 */
const requestByBody = (path, params) => fetch(path, {
    method: 'POST',
    headers: {'Content-Type': 'application/json; charset=utf-8'},
    body: typeof params !== 'undefined' ? JSON.stringify(params) : '',
});

/**
 * 参与作为BODY的请求方式。
 */
const requestByForm = (path, params) => fetch(path, {
    method: 'POST',
    headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'},
    body: new URLSearchParams(params),
});

/**
 * 通用的远程请求。
 */
const request = (method, url, params) => {
    const fn = (method === 'GET') ? requestByUrl : ((method === 'POST') ? requestByBody : requestByForm);
    return fn(url, params).then(response => new Promise((resolve, reject) => {
        const publish = response.ok ? resolve : reject;
        response.json().then(data => publish(data));
    }));
};

export const useRequest = (url, method = 'GET') => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const {message} = App.useApp();
    const run = useCallback((params = undefined) => {
        return request(method, url, params)
            .then(data => {
                setData(data);
                setLoading(false);
                return Promise.resolve(data);
            })
            .catch(exception => {
                message.error(exception.message).then(() => {});
                return Promise.reject(exception);
            });
    }, [method, url, setLoading, setData, message]);
    return {
        loading,
        data,
        run,
    }
};
