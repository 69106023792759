import { Menu } from 'antd';
import state from 'data/state';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import Brand from 'views/components/Brand';
import Columns, { Content, Left } from 'views/layouts/Columns';
import './index.scss';

export default () => {
    const location = useLocation();
    const navigate = useNavigate();
    const setPatient = useSetRecoilState(state.patient);
    const selectedKey = useMemo(() => {
        if (location.pathname.startsWith('/patients')) {
            return 'PatientManagement';
        } else if (location.pathname === '/') {
            return 'IndicatorCalculator';
        } else {
            return 'Unknown';
        }
    }, [location]);
    const toIndicatorCalculator = useCallback(() => {
        if (selectedKey !== 'IndicatorCalculator') {
            navigate('/');
            setPatient(null);
        }
    }, [selectedKey, navigate, setPatient])
    const toPatientManagement = useCallback(() => {
        if (selectedKey !== 'PatientManagement') {
            navigate('/patients');
        }
    }, [selectedKey, navigate])
    return (
        <Columns className="navigator">
            <Left>
                <Brand />
            </Left>
            <Content>
                <Menu
                    className="modules"
                    mode="horizontal"
                    selectedKeys={[selectedKey]}
                    items={[{
                        key: 'IndicatorCalculator',
                        label: '健康计算器',
                        onClick: toIndicatorCalculator,
                    }, {
                        key: 'PatientManagement',
                        label: '患者档案管理',
                        onClick: toPatientManagement,
                    }]}
                />
            </Content>
        </Columns>
    );
};
