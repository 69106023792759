import { Button, Result } from 'antd';

export default () => {
    return (
        <Result
            status="404"
            title="Not Found"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary">Back Home</Button>}
        />
    );
};
