import { Descriptions } from 'antd';

export default ({
                    data: {
                        glucose_status_judge = {},
                        islet_status_judge = {},
                        hyperlipemia_status_judge = {},
                        hepatic_status_judge = {},
                        renal_status_judge = {},
                        ua_status_judge = {},
                        hypertension_status_judge = {},
                        oversize_judge = {},
                        hypoglycaemia_risk_judge = {},
                    },
                }) => {
    return (
        <>
            <Descriptions title={<span id="glucose-status">血糖状态</span>} bordered column={1}>
                <Descriptions.Item label="血糖状态">{glucose_status_judge.glucose_status || ''}</Descriptions.Item>
                <Descriptions.Item label="低血糖风险">{hypoglycaemia_risk_judge.hypoglycaemia_risk}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="islet-status">胰岛状态</span>} bordered column={1}>
                <Descriptions.Item label="胰岛功能情况">{islet_status_judge.islet_status || ''}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="hyperlipemia-status">血脂状态</span>} bordered column={1}>
                <Descriptions.Item
                    label="总胆固醇(TC)状态">{hyperlipemia_status_judge.tc_status || ''}</Descriptions.Item>
                <Descriptions.Item
                    label="甘油三酯(TG)状态">{hyperlipemia_status_judge.tg_status || ''}</Descriptions.Item>
                <Descriptions.Item
                    label="低密度脂蛋白胆固醇(LDL-C)状态">{hyperlipemia_status_judge.ldlc_status || ''}</Descriptions.Item>
                <Descriptions.Item
                    label="是否高血脂">{hyperlipemia_status_judge.is_hyperlipemia || ''}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="hepatic-status">肝功能状态</span>} bordered column={1}>
                <Descriptions.Item
                    label="丙氨酸氨基转移酶(ALT)状态">{hepatic_status_judge.alt_status || ''}</Descriptions.Item>
                <Descriptions.Item
                    label="天门冬氨酸氨基转移酶(AST)状态">{hepatic_status_judge.ast_status || ''}</Descriptions.Item>
                <Descriptions.Item
                    label="肝功能异常">{hepatic_status_judge.hepatic_dysfunction || ''}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="renal-status">肾功能状态</span>} bordered column={1}>
                <Descriptions.Item label="肌酐清除率(CCR)">{renal_status_judge.ccr || ''}</Descriptions.Item>
                <Descriptions.Item label="肌酐清除率(CCR)状态">{renal_status_judge.ccr_status || ''}</Descriptions.Item>
                <Descriptions.Item label="估算的肾小球滤过率(eGFR)">{renal_status_judge.egfr || ''}</Descriptions.Item>
                <Descriptions.Item label="肾功能风险">{renal_status_judge.renal_risk || ''}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="ua-status">尿酸状态</span>} bordered column={1}>
                <Descriptions.Item label="血尿酸(UA)状态">{ua_status_judge.ua_status || ''}</Descriptions.Item>
                <Descriptions.Item
                    label="尿酸高或痛风">{ua_status_judge.is_hyperuricaemia_gout || ''}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="hypertension-status">血压状态</span>} bordered column={1}>
                <Descriptions.Item
                    label="高血压分级">{hypertension_status_judge.hypertension_level || ''}</Descriptions.Item>
                <Descriptions.Item
                    label="是否高血压">{hypertension_status_judge.is_hypertension || ''}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="oversize-status">肥胖状态</span>} bordered column={1}>
                <Descriptions.Item label="体重类型">{oversize_judge.oversize_type || ''}</Descriptions.Item>
                <Descriptions.Item label="是否超重">{oversize_judge.is_oversize || ''}</Descriptions.Item>
            </Descriptions>
        </>
    )
}
