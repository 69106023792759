import { Anchor } from 'antd';
import './index.scss';

export default () => {
    return (
        <section className="indexes">
            <Anchor items={[{
                key: 'health-indicators',
                href: '#health-indicators',
                title: '健康指标',
                children: [{
                    key: 'evalLab-criteria',
                    href: '#evalLab-criteria',
                    title: '通用型指标',
                    children: [{
                        key: 'glucose-status',
                        href: '#glucose-status',
                        title: '血糖状态',
                    }, {
                        key: 'islet-status',
                        href: '#islet-status',
                        title: '胰岛状态',
                    }, {
                        key: 'hyperlipemia-status',
                        href: '#hyperlipemia-status',
                        title: '血脂状态',
                    }, {
                        key: 'hepatic-status',
                        href: '#hepatic-status',
                        title: '肝功能状态',
                    }, {
                        key: 'renal-status',
                        href: '#renal-status',
                        title: '肾功能状态',
                    }, {
                        key: 'ua-status',
                        href: '#ua-status',
                        title: '尿酸状态',
                    }, {
                        key: 'hypertension-status',
                        href: '#hypertension-status',
                        title: '血压状态',
                    }, {
                        key: 'oversize-status',
                        href: '#oversize-status',
                        title: '肥胖状态',
                    }]
                }, {
                    key: 'determ-applicability-dm2',
                    href: '#determ-applicability-dm2',
                    title: '适用性判断',
                }, {
                    key: 'metabolic-info',
                    href: '#metabolic-info',
                    title: '代谢指标',
                }, {
                    key: 'ctrl-value',
                    href: '#ctrl-value',
                    title: '指标阈值',
                    children: [{
                        key: 'weight-indicator',
                        href: '#weight-indicator',
                        title: '体重阈值',
                    }, {
                        key: 'body-fat-ratio-indicator',
                        href: '#body-fat-ratio-indicator',
                        title: '体脂比阈值',
                    }, {
                        key: 'waist-indicator',
                        href: '#waist-indicator',
                        title: '腰围阈值',
                    }, {
                        key: 'glucose-0-min-indicator',
                        href: '#glucose-0-min-indicator',
                        title: '空腹血糖阈值',
                    }, {
                        key: 'glucose-120-min-indicator',
                        href: '#glucose-120-min-indicator',
                        title: '餐后2小时血糖阈值',
                    }, {
                        key: 'hba1c-indicator',
                        href: '#hba1c-indicator',
                        title: '糖化血红蛋白阈值',
                    }, {
                        key: 'biochemical-indicator',
                        href: '#biochemical-indicator',
                        title: '生化指标阈值',
                    }, {
                        key: 'blood-pressure-indicator',
                        href: '#blood-pressure-indicator',
                        title: '血压阈值',
                    }]
                }]
            }]} />
        </section>
    );
};
