import './index.scss';

export const Header = ({ children, ...props }) => (
    <header {...props}>
        {children}
    </header>
);

export const Content = ({ children, ...props }) => (
    <main {...props}>
        {children}
    </main>
);

export const Footer = ({ children, ...props }) => (
    <footer {...props}>
        {children}
    </footer>
);

/**
 * 行布局：可以指定任意多个吸顶或吸底的行，正文部分填充满剩余高度。
 */
export default ({ className, bordered = false, children, ...props }) => {
    const clazz = bordered ? 'rows bordered' : 'rows';
    return (
        <section className={className ? `${clazz} ${className}` : clazz} {...props}>
            {children}
        </section>
    );
}
