import './index.scss';

export const Side = ({ children, ...props }) => (
    <aside {...props}>
        {children}
    </aside>
);

export const Left = ({ className, children, ...props }) => (
    <Side className={className ? `left ${className}` : 'left'} {...props}>{children}</Side>
);

export const Content = ({ children, ...props }) => (
    <main {...props}>
        {children}
    </main>
);

export const Right = ({ className, children, ...props }) => (
    <Side className={className ? `right ${className}` : 'right'} {...props}>{children}</Side>
);

/**
 * 列布局：可以指定任意多个左侧或右侧的列，正文部分填充满剩余宽度。
 */
export default ({ className, bordered = false, children, ...props }) => {
    const clazz = bordered ? 'columns bordered' : 'columns';
    return (
        <section className={className ? `${clazz} ${className}` : clazz} {...props}>
            {children}
        </section>
    );
};
