import Indexes from 'views/components/Indexes';
import Indicators from 'views/components/Indicators';
import Profile from 'views/components/Profile';
import Columns, { Content, Left, Right } from 'views/layouts/Columns';

export default () => (
    <Columns bordered>
        <Left>
            <Profile/>
        </Left>
        <Content>
            <Indicators/>
        </Content>
        <Right>
            <Indexes/>
        </Right>
    </Columns>
);
