import { UserOutlined } from "@ant-design/icons";
import { Avatar, List } from 'antd';
import { useFetchPatients } from 'data/patients';
import state, { columns } from 'data/state';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import './index.scss';

export default () => {
    const fetchProfiles = useFetchPatients();
    const patients = useRecoilValue(state.patients);
    const [selectedPatient, setPatient] = useRecoilState(state.patient);

    useEffect(() => {
        fetchProfiles();
    }, []);

    return (
        <List
            className="patients"
            itemLayout="horizontal"
            dataSource={patients}
            loading={patients.length === 0}
            renderItem={(patient) => (
                <List.Item
                    key={patient[columns['患者编号']]}
                    className={patient[columns['患者编号']] === selectedPatient?.[columns['患者编号']] ? 'selected' : ''}
                    onClick={() => setPatient(patient)}
                >
                    <List.Item.Meta
                        avatar={<Avatar size={48} icon={<UserOutlined/>}/>}
                        title={`患者-${patient[columns['患者编号']]}`}
                        description={`${patient[columns['开始日期']]}-${patient[columns['结束日期']]}`}
                    />
                </List.Item>
            )}
        />
    );
};
