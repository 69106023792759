import { Typography } from 'antd';
import state from 'data/state';
import { useRecoilValue } from 'recoil';
import CtrlValue from 'views/components/Indicators/CtrlValue';
import DetermApplicabilityDm2 from 'views/components/Indicators/DetermApplicabilityDm2';
import DietPolicy from 'views/components/Indicators/DietPolicy';
import EvalLabCriteria from 'views/components/Indicators/EvalLabCriteria';
import MetabolicInfo from 'views/components/Indicators/MetabolicInfo';
import TargetSuggestion from 'views/components/Indicators/TargetSuggestion';
import TrainingProgram from 'views/components/Indicators/TrainingProgram';
import './index.scss';

export default () => {
    const {
        evalLabCriteria = {},
        determApplicabilityDm2 = {},
        metabolicInfo = {},
        ctrlValue = {},
        targetSuggestion = {},
        trainingProgram = {},
        dietPolicy = {},
    } = useRecoilValue(state.indicators);
    return (
        <section className="outputs">
            <Typography>
                <Typography.Title id="health-indicators" level={3}>健康指标</Typography.Title>
                <Typography.Title id="evalLab-criteria" level={4}>通用型指标</Typography.Title>
                <Typography.Paragraph>
                    <EvalLabCriteria data={evalLabCriteria}/>
                </Typography.Paragraph>
                <Typography.Title id="determ-applicability-dm2" level={4}>适用性判断</Typography.Title>
                <Typography.Paragraph>
                    <DetermApplicabilityDm2 data={determApplicabilityDm2}/>
                </Typography.Paragraph>
                <Typography.Title id="metabolic-info" level={4}>代谢指标</Typography.Title>
                <Typography.Paragraph>
                    <MetabolicInfo data={metabolicInfo}/>
                </Typography.Paragraph>
                <Typography.Title id="ctrl-value" level={4}>指标阈值</Typography.Title>
                <Typography.Paragraph>
                    <CtrlValue data={ctrlValue}/>
                </Typography.Paragraph>
                {/*<Typography.Title level={4}>目标计算</Typography.Title>*/}
                {/*<Typography.Paragraph>*/}
                {/*    <TargetSuggestion data={targetSuggestion}/>*/}
                {/*</Typography.Paragraph>*/}
                {/*<Typography.Title level={4}>运动处方</Typography.Title>*/}
                {/*<Typography.Paragraph>*/}
                {/*    <TrainingProgram data={trainingProgram}/>*/}
                {/*</Typography.Paragraph>*/}
                {/*<Typography.Title level={4}>饮食处方</Typography.Title>*/}
                {/*<Typography.Paragraph>*/}
                {/*    <DietPolicy data={dietPolicy}/>*/}
                {/*</Typography.Paragraph>*/}
            </Typography>
        </section>
    );
};