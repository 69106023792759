import { Descriptions } from 'antd';

export default ({data: {
    weightIndicator = {},
    bodyFatRatioIndicator = {},
    waistIndicator = {},
    glucose0MinIndicator = {},
    glucose120MinIndicator = {},
    hba1cIndicator = {},
    biochemicalIndicator = {},
    bloodPressureIndicator = {},
}}) => {
    return (
        <>
            <Descriptions title={<span id="weight-indicator">体重阈值</span>} bordered column={1}>
                <Descriptions.Item label="英文名字">{weightIndicator.indicator_name_en || ''}</Descriptions.Item>
                <Descriptions.Item label="标准体重">{weightIndicator.std_weight || ''}</Descriptions.Item>
                <Descriptions.Item label="指标上界">{weightIndicator.ctrl_weight_high || ''}</Descriptions.Item>
                <Descriptions.Item label="指标下界">{weightIndicator.ctrl_weight_low || ''}</Descriptions.Item>
                <Descriptions.Item label="减重空间">{weightIndicator.weight_loss_tolerance || ''}</Descriptions.Item>
                <Descriptions.Item label="体重达标预计周数">{weightIndicator.std_weight_reach_weekth || ''}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="body-fat-ratio-indicator">体脂比阈值</span>} bordered column={1}>
                <Descriptions.Item label="英文名称">{bodyFatRatioIndicator.indicator_name_en || ''}</Descriptions.Item>
                <Descriptions.Item label="体脂比控制阈值上限">{bodyFatRatioIndicator.ctrl_bfp_high || ''}</Descriptions.Item>
                <Descriptions.Item label="体脂比控制阈值下限">{bodyFatRatioIndicator.ctrl_bfp_low || ''}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="waist-indicator">腰围阈值</span>} bordered column={1}>
                <Descriptions.Item label="英文名称">{waistIndicator.indicator_name_en || ''}</Descriptions.Item>
                <Descriptions.Item label="腰围控制阈值上限">{waistIndicator.ctrl_waist_high || ''}</Descriptions.Item>
                <Descriptions.Item label="腰围控制阈值下限">{waistIndicator.ctrl_waist_low || ''}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="glucose-0-min-indicator">空腹血糖阈值</span>} bordered column={1}>
                <Descriptions.Item label="英文名称">{glucose0MinIndicator.indicator_name_en || ''}</Descriptions.Item>
                <Descriptions.Item label="空腹血糖控制阈值上限">{glucose0MinIndicator.ctrl_fpg_high || ''}</Descriptions.Item>
                <Descriptions.Item label="空腹血糖控制阈值下限">{glucose0MinIndicator.ctrl_fpg_low || ''}</Descriptions.Item>
                <Descriptions.Item label="空腹血糖达标预计周数">{glucose0MinIndicator.std_fpg_reach_weekth || ''}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="glucose-120-min-indicator">餐后2小时血糖阈值</span>} bordered column={1}>
                <Descriptions.Item label="英文名称">{glucose120MinIndicator.indicator_name_en || ''}</Descriptions.Item>
                <Descriptions.Item label="餐后2h血糖控制阈值上限">{glucose120MinIndicator.ctrl_2hpg_high || ''}</Descriptions.Item>
                <Descriptions.Item label="餐后2h血糖控制阈值下限">{glucose120MinIndicator.ctrl_2hpg_low || ''}</Descriptions.Item>
                <Descriptions.Item label="餐后血糖达标预计周数">{glucose120MinIndicator.std_2hpg_reach_weekth || ''}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="hba1c-indicator">糖化血红蛋白阈值</span>} bordered column={1}>
                <Descriptions.Item label="英文名称">{hba1cIndicator.indicator_name_en || ''}</Descriptions.Item>
                <Descriptions.Item label="糖化血红蛋白控制阈值上限">{hba1cIndicator.ctrl_hba1c_high || ''}</Descriptions.Item>
                <Descriptions.Item label="糖化血红蛋白控制阈值下限">{hba1cIndicator.ctrl_hba1c_low || ''}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="biochemical-indicator">生化指标阈值</span>} bordered column={1}>
                <Descriptions.Item label="英文名称">{biochemicalIndicator.indicator_name_en || ''}</Descriptions.Item>
                <Descriptions.Item label="甘油三酯(TG)控制阈值上限">{biochemicalIndicator.ctrl_tg_high || ''}</Descriptions.Item>
                <Descriptions.Item label="甘油三酯(TG)控制阈值下限">{biochemicalIndicator.ctrl_tg_low || ''}</Descriptions.Item>
                <Descriptions.Item label="低密度脂蛋白胆固醇(LDL-C)控制阈值上限">{biochemicalIndicator.ctrl_ldlc_high || ''}</Descriptions.Item>
                <Descriptions.Item label="低密度脂蛋白胆固醇(LDL-C)控制阈值下限">{biochemicalIndicator.ctrl_ldlc_low || ''}</Descriptions.Item>
                <Descriptions.Item label="总胆固醇(TC)控制阈值上限">{biochemicalIndicator.ctrl_tc_high || ''}</Descriptions.Item>
                <Descriptions.Item label="总胆固醇(TC)控制阈值下限">{biochemicalIndicator.ctrl_tc_low || ''}</Descriptions.Item>
                <Descriptions.Item label="丙氨酸氨基转移酶(ALT)控制阈值上限">{biochemicalIndicator.ctrl_alt_high || ''}</Descriptions.Item>
                <Descriptions.Item label="丙氨酸氨基转移酶(ALT)控制阈值下限">{biochemicalIndicator.ctrl_alt_low || ''}</Descriptions.Item>
                <Descriptions.Item label="天门冬氨酸氨基转移酶(AST)控制阈值上限">{biochemicalIndicator.ctrl_ast_high || ''}</Descriptions.Item>
                <Descriptions.Item label="天门冬氨酸氨基转移酶(AST)控制阈值下限">{biochemicalIndicator.ctrl_ast_low || ''}</Descriptions.Item>
            </Descriptions>
            <Descriptions title={<span id="blood-pressure-indicator">血压阈值</span>} bordered column={1}>
                <Descriptions.Item label="英文名称">{bloodPressureIndicator.indicator_name_en || ''}</Descriptions.Item>
                <Descriptions.Item label="收缩压控制阈值上限">{bloodPressureIndicator.ctrl_systolic_high || ''}</Descriptions.Item>
                <Descriptions.Item label="收缩压控制阈值下限">{bloodPressureIndicator.ctrl_systolic_low || ''}</Descriptions.Item>
                <Descriptions.Item label="舒张压控制阈值上限">{bloodPressureIndicator.ctrl_diastolic_high || ''}</Descriptions.Item>
                <Descriptions.Item label="舒张压控制阈值下限">{bloodPressureIndicator.ctrl_diastolic_low || ''}</Descriptions.Item>
            </Descriptions>
        </>
    )
}
